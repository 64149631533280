import React from "react";
import Style from "./style"

function Function() {
    return(
        <section className="member-count-section">
            <div className="container">
                <div className="col-sm-12 first-12">

                    <div className="col-sm-6 first-dot-flex">
                        <div className="years-dots-col">
                            <h2 className="header-main-text">YEARS</h2>
                            <span className="bold-number-text">5</span>
                        </div>

                        <div>
                            <div className="years-list-dots">
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-6">

                        <div className="team-flex-div">
                            <div>
                                <span className="bold-number-text">63</span></div>
                            <div>
                                <h2 className="header-main-text">TEAM</h2>
                            </div>
                        </div>

                        <div className="team-flex-div">
                            <div>
                                <img src="/images/user-img.webp" className="img-responsive" alt=""/></div>
                            <div>
                                <h2 className="header-main-text">MEMBERS</h2>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="col-md-12 first-12 first-12-2">
                    <div className="col-sm-6 first-dot-flex">
                        <div className="years-dots-col">
                            <h2 className="header-main-text">CITIES</h2>
                            <span className="bold-number-text">15</span>
                        </div>


                    </div>
                    <div className="col-sm-6 countries-block">
                        <div> <span className="bold-number-text">12</span>

                        </div>
                        <div>
                            <h2 className="header-main-text">COUNTRIES</h2>
                        </div>
                    </div>
                </div></div>

<Style/>
        </section>
    )
}
export default Function;