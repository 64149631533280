import React from "react";
import Style from "./style"
function WorkingUs() {
    return(
        <div className="container">

            <section className="get-in-touch-section work-process">
                <div className="container">
                    <div className="col-md-12 slected-padding-col">
                        <h2 className=".header-main-text selected-heading">
                            WORKING WITH US </h2>
                        <hr className="user-slider-line" />
                        <p className="selected-p">To make your project work the way it needs to, you need a proven
                            team with oodles of experience. Redsqware offers a broad range of expertise and today’s most hard to find, business-critical technical specialties.

                        </p>
                    </div>
                </div>
            </section>

            <div className="about-box-cards">
                <div className="col-sm-12">
                    <div className="col-sm-5">
                        <img className="media-object img-responsive" src="/images/box-icon-1.webp" alt="..."/>
                            <div><h3 className="services-small-heading">
                                Weekly Check-ins</h3>
                                <p className="services-detail-text">Redsqware may not be located nearby, but we want you to feel like we are. We’ll have (at minimum) a weekly phone call to review progress and address questions / concerns on both sides.</p>
                            </div>
                    </div>
                    <div className="col-sm-5">
                        <img className="media-object img-responsive" src="/images/box-icon-1.webp" alt="..."/>
                            <div><h3 className="services-small-heading">
                                Dedicated Team Members</h3>
                                <p className="services-detail-text">The same Redsqware designers and / or developers will stick with your project through the entire cycle. That means no time is wasted bringing somebody up to speed, and you’ll have dedicated communication every step of the way</p>
                            </div>
                    </div>
                    <div className="col-sm-5">
                        <img className="media-object img-responsive" src="/images/box-icon-1.webp" alt="..."/>
                            <div><h3 className="services-small-heading">
                                Milestone Recaps</h3>
                                <p className="services-detail-text">After every major release, we’ll review our initial goals and determine where we succeeded, where we may have missed the mark, and how to better our relationship for future phases of work.</p>
                            </div>
                    </div>
                    <div className="col-sm-5">
                        <img className="media-object img-responsive" src="/images/box-icon-1.webp" alt="..."/>
                            <div><h3 className="services-small-heading">
                                End-of-week Reports</h3>
                                <p className="services-detail-text">We send you a summary every Friday that details where we spent our time and the accompanying progress towards your goals.</p>
                            </div>
                    </div>
                </div>
            </div>
            <Style/>
        </div>
    )
}
export default WorkingUs;