import React from 'react'

export default () => (
    <style jsx="true">{`
.member-count-section{
    background: #232529 url(/images/counter-img.webp) no-repeat bottom;
    background-size: 100% 60%;
    min-height: 376px;

    padding: 24px 0px;
}
.member-count-section  .years-dots-col {
    display: flex;
    justify-content: space-between;
    align-items: center
}
.member-count-section .header-main-text{
    color: #929292;
    margin: unset;
}
.bold-number-text{
    font-size: 68px;
    overflow: hidden;
    color: #6a6a6a;
    font-weight: 800;
    margin: 0;
    line-height: 68px;
    float: left;
}
.years-list-dots ul  {
    height: 1px;
    background-color: #525252;
    display: flex;
    justify-content: space-between;
    margin-top:32px;
}
.years-list-dots ul li {
    list-style: none;
    height: 20px;
    width: 20px;
    border: 1px solid #525252;
    background-color: #525252;
    border-radius: 100%;
    position: relative;
    top: -10px;
    float: left;
}
.first-dot-flex{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #6a6a6a;
    text-align: -webkit-center;
    min-height: 175px;
}
.first-12{
    border-bottom: 1px solid #6a6a6a;
    display: flex;
}
.team-flex-div{
    display: flex;
    align-items: center
}
.team-flex-div .header-main-text{
    padding-left: 30px
}
.countries-block{
  display: flex;
  flex-direction: column;
  padding-top: 9px;
}
.first-12-2{
    border-bottom: none;
}

    `}</style>
)