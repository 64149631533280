import React from "react";
import Style from "./style"
function AboutService() {
    return(
<div className="container">
    <div className="services-data">
        <div className="col-sm-6 about-border-service-card " data-aos="fade-left">
            <div className="Services-detail">
                <h3 className="services-small-heading">
                    At Redsqware, we build tech teams. But it’s how we do it that makes all the difference.
                </h3>
                <p className="services-detail-text"> Today's web technology
                    is more open than ever before, putting custom web applications within the reach of any
                    enterprise. Redsqware has extensive expertise in designing and developing powerful, stable,
                    and cost-effective web applications for dema
                    nding use cases – such as engineering, healthcare, and financial services.
                </p>
                <div>
                    <button className="btn red-btn">Case Studies</button>
                    <button className="btn black-btn">LeaderShip</button>
                </div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="servives-img  " data-aos="zoom-in">
                <img src="/images/post-img-7.webp" className="img-responsive center-block" alt=""/>
            </div>
        </div>

    </div>

    <div className="services-data" >
        <div className="col-sm-6">
            <div className="servives-img" data-aos="zoom-in">
                <img src="/images/post-img-8.webp" className="img-responsive " alt=""/>
            </div>
        </div>
        <div className="col-sm-6 about-border-service-card wow fadeInRight" data-aos="fade-right">
            <div className="Services-detail">
                <h3 className="services-small-heading">
                    What We Do
                </h3>
                <p className="services-detail-text">Redsqware provides custom web design and development for some
                    of the world's best-known brands. Our nationally award-winning specialists deliver
                    solutions you can rely on for business-critical operations. We've helped to create valuable
                    properties and products that are used, loved, and shared by millions of users.</p>
                <button className="btn red-btn">Our Services</button>
            </div>
        </div>
    </div>
    <Style/>
</div>
    )
}
export default AboutService