import React from 'react'

export default () => (
    <style jsx="true">{`
.selected-p {
    color: #595d66;
    font-weight: 500;
    max-width: 600px;
    text-align: center;
    margin: 0 auto 0 auto;
}
.user-slider-line {
    width: 70px;
    background-color: #d72c42;
    height: 3px;
}
.slected-padding-col {
    padding: 90px 0px;
}
.selected-heading {
    text-align: center;
    font-size: 50px;
    font-weight: 300;
}

    `}</style>
)