import React from "react";
import Layout from "../components/layout";
import AboutComponent from "../components/aboutpagecomponent"
function About() {
    return (
        <div>
            <Layout headimg ={"/images/top-logo.webp"} headclr={"white-list"}  headbg={"transparent-bg"} callicon ={"/images/call-answer.webp"}>
                <AboutComponent/>
            </Layout>
        </div>
    )
}
export default About;