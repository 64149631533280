import React from "react";
import Style from "./style"

function WidthCard() {
    return(
<div>
    <section className="get-in-touch-section work-process">
        <div className="container">
            <div className="col-md-12 slected-padding-col">
                <h2 className=".header-main-text selected-heading">
                    WORK PROCESS </h2>
                <hr className="user-slider-line" />
                <p className="selected-p">At Redsqware, we strive to find the right combination of flexibility and
                    structure for our clients—flexible in adapting to their company or team composition, but
                    structured in adhering to processes our consulting expertise prescribes.</p>
            </div>
        </div>
    </section>

    <section className="hide-cards-section">
        <div className="container">
            <div className="services-data about-border-service-long-card data" data-aos="fade-up" >
                <div className="col-sm-6">
                    <div className="Services-detail">
                        <h3 className="services-small-heading">
                            Discover
                        </h3>
                        <p className="services-detail-text">We’ll arrange a workshop to perform a deep dive into
                            your problem and deliver a detailed plan of action and estimate for your next steps

                        </p>

                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="servives-img " data-aos="zoom-in">
                        <img src="/images/post-img-11.webp" className="img-responsive center-block" alt=""/>
                    </div>
                </div>

            </div>

            <div className="services-data about-border-service-long-card" data-aos="fade-up" >
                <div className="col-sm-6">
                    <div className="servives-img  wow zoomIn" data-aos="zoom-in">
                        <img src="/images/post-img-12.webp" className="img-responsive center-block" alt=""/>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="Services-detail">
                        <h3 className="services-small-heading">

                            Design + Development
                        </h3>
                        <p className="services-detail-text">It’s application build time,
                            complete with real data, visual polish, and delightful interactions.
                        </p>

                    </div>
                </div>
            </div>

            <div className="services-data about-border-service-long-card" data-aos="fade-up" >
                <div className="col-sm-6">
                    <div className="Services-detail">
                        <h3 className="services-small-heading">
                            Deliver
                        </h3>
                        <p className="services-detail-text">
                            Redsqware sends your application off into the world while future feature
                            development and maintenance shift to the forefront.
                        </p>

                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="servives-img  wow zoomIn" data-aos="zoom-in">
                        <img src="/images/post-img-13.webp" className="img-responsive center-block" alt=""/>
                    </div>
                </div>

            </div>
        </div>

    </section>
<Style/>
</div>
    )
}
export default WidthCard