import React from "react";
import AboutMain from "../aboutmain";
import AboutService from "../aboutservices";
import WidthCard from "../aboutwidthcard";
import WorkingUS from "../workingwithus";
import Member from "../countersection"
import Bars from "../barssection";

function AboutPageComponent() {
    return(
        <div>
            <AboutMain/>
            <AboutService/>
            <WidthCard/>
            <WorkingUS/>
            <Member/>
            <Bars/>
        </div>
    )
}
export default AboutPageComponent;