import React from "react";
import Style from "./style"
function Bars() {
    return(
<div>
    <section className="about-progess-section">
        <div className="container">
            <div className="services-data">
                <div className="col-sm-6">
                    <div className="Services-detail">
                        <h3 className="services-small-heading">
                            100% Agility
                        </h3>
                        <p className="services-detail-text"> Redsqware employs fully agile development methodology, including online project management, documentation, and specification. Our work agreements guarantee that the highest standards for professionalism, flexibility, and quality will be adhered to – and that milestones are met.

                        </p>
                        <button className="btn red-btn">What We Do</button>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="about-progress-block">
                        <div className="about-progess-content">
                            <div className="tech-percentage">
                                <p>Web Design</p>
                                <p>55%</p>   </div>

                            <div className="progress-bar-div">
                                <div className="progress-line progress-line-50">

                                </div>
                            </div>

                        </div>
                        <div className="about-progess-content">
                            <div className="tech-percentage">
                                <p>Developers</p>
                                <p>90%</p>   </div>

                            <div className="progress-bar-div">
                                <div className="progress-line progress-line-90" >

                                </div>
                            </div>

                        </div>
                        <div className="about-progess-content">
                            <div className="tech-percentage">
                                <p>WordPress</p>
                                <p>65%</p>   </div>

                            <div className="progress-bar-div">
                                <div className="progress-line progress-line-65" >

                                </div>
                            </div>

                        </div>
                        <div className="about-progess-content">
                            <div className="tech-percentage">
                                <p>Applications</p>
                                <p>79%</p>   </div>

                            <div className="progress-bar-div">
                                <div className="progress-line progress-line-79" >

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
<Style/>
</div>
    )
}
export default Bars;