import React from 'react';
export default () => (
    <style jsx="true">{`
.tech-percentage{
    display: flex;
    justify-content: space-between
}
.progress-bar-div{
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1)
}
.about-progess-section{
    background-color: #fff6f6;
}
.progress-line{
    position: relative;
    margin: 5px 0 5px 10px;
    height: 50%;
    background-color: #ff003c;
    float: left
}
.about-progess-content p{ font-size: 14px;}
.progress-line-50{
width:50%;
}
.progress-line-90{
width:90%;
}
.progress-line-65{
width:65%;
}
.progress-line-79{
width:79%;
}
    `}</style>
)