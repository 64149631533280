import React from 'react'

export default () => (
    <style jsx="true">{`
.about-border-service-card,.about-border-service-long-card{
    background-color: #fff;
    padding: 35px 45px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.14);
    border-radius: 4px;
}
.about-page-white-section{
    background-color: white;
}
.about-box-cards{
    padding-bottom: 50px;
}
.about-box-cards .col-sm-12{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}
.about-box-cards .col-sm-12 .col-sm-5{
display: -webkit-inline-box;
}
.about-box-cards img{
    margin-right: 21px;
    margin-top: 7px;
}
.services-data {
    display: flex;
    padding: 40px 0px;
}
    `}</style>
)